<template>
  <div>
    <v-alert
      text
      prominent
      border="left"
      v-if="!loading && !isSchoolProfilePresent"
      type="error"
    >
      Nur Angehörige der Schule haben ein Schulprofil.
    </v-alert>
    <v-card v-if="isStudentInfoVisible" class="mb-4" v-bind="$attrs">
      <v-system-bar>Person</v-system-bar>
      <v-list>
        <ListItem title="soziales Geschlecht">
          <LookupValue :value="person.gender" />
        </ListItem>
        <ListItem title="amtlicher Vorname">
          <StaticText :value="profile.officialFirstName" />
        </ListItem>
        <ListItem v-if="person.schoolClass" title="Klasse">
          <v-chip-group column>
            <SchoolClassChip :value="person.schoolClass"></SchoolClassChip>
          </v-chip-group>
        </ListItem>

        <ListItem title="Ämtli">
          <v-chip-group v-if="person.duties && person.duties.length > 0" column>
            <v-chip
              class="pt-0 mt-0"
              outlined
              v-for="(item, index) in person.duties"
              :key="index"
              :value="item"
            >
              <v-icon class="mr-2" small>{{ studentDutyIcon(item) }}</v-icon>
              {{ item.description }}
            </v-chip>
          </v-chip-group>
          <span v-else>—</span>
        </ListItem>

        <ListItem title="Besonderes">
          <v-chip-group
            v-if="person.special && person.special.length > 0"
            column
          >
            <v-chip
              class="pt-0 mt-0"
              outlined
              v-for="(item, index) in person.special"
              :key="index"
              :value="item"
            >
              <v-icon class="mr-2" small>{{ studentSpecialIcon(item) }}</v-icon>
              {{ item.description }}
            </v-chip>
          </v-chip-group>
          <span v-else>—</span>
        </ListItem>
        <ListItem title="AHV">
          <StaticText :value="profile.socialSecurityNumber" />
        </ListItem>
      </v-list>
      <v-card-text v-if="isCommentsVisible"
        >Diese Informationen sind für die Mitarbeitenden der Schule
        sichtbar.</v-card-text
      >
    </v-card>

    <v-card v-if="isEmployeeInfoVisible" class="mb-4" v-bind="$attrs">
      <v-system-bar>Person</v-system-bar>
      <v-list>
        <ListItem title="soziales Geschlecht">
          <LookupValue :value="person.gender" />
        </ListItem>
        <ListItem v-if="profile.phoneSchool" title="Telefon Schule">
          <PhoneNumber :value="profile.phoneSchool" />
        </ListItem>
        <ListItem v-if="person.code" title="Kürzel">
          <StaticText :value="person.code" />
        </ListItem>
        <ListItem v-if="profile.departmentHeadOf" title="Fachschaftsvorsitz">
          <v-chip-group column>
            <DepartmentChip
              v-for="(department, index) in profile.departmentHeadOf"
              :key="index"
              :value="department"
            />
          </v-chip-group>
        </ListItem>
        <ListItem v-if="profile.departments" title="Fachschaften">
          <v-chip-group column>
            <DepartmentChip
              v-for="(department, index) in profile.departments"
              :key="index"
              :value="department"
            />
          </v-chip-group>
        </ListItem>
        <ListItem v-if="profile.classTeacherOf" title="Klassenlehrperson von">
          <v-chip-group column>
            <SchoolClassChip
              v-for="(schoolClass, index) in profile.classTeacherOf"
              :key="index"
              :value="schoolClass"
            />
          </v-chip-group>
        </ListItem>
        <ListItem title="IBAN">
          <StaticText :value="profile.iban" />
        </ListItem>
        <ListItem title="AHV">
          <StaticText :value="profile.socialSecurityNumber" />
        </ListItem>
      </v-list>
      <v-card-text v-if="isCommentsVisible"
        >Diese Informationen sind für die Schuladministration
        sichtbar.</v-card-text
      >
    </v-card>

    <v-card v-if="isAccountPresent" class="mb-4" v-bind="$attrs">
      <v-system-bar>Benutzerkonto</v-system-bar>
      <v-list>
        <ListItem title="Kontoname kurz">
          <StaticText :value="profile.account.samAccountName" />
        </ListItem>
        <ListItem title="Kontoname lang">
          <StaticText :value="profile.account.userPrincipalName" />
          <CopyButton :value="profile.account.userPrincipalName"></CopyButton>
        </ListItem>
        <ListItem v-if="profile.permissions" title="Spezialberechtigungen">
          <v-chip-group column>
            <v-chip
              class="pt-0 mt-0"
              outlined
              v-for="(permission, index) in profile.account.permissions"
              :key="index"
              :value="permission"
            >
              {{ permission.description }}
            </v-chip>
          </v-chip-group>
        </ListItem>
        <ListItem title="Ausweis / Druckkarte"
          ><samp><StaticText :value="profile.account.legicUid" /></samp>
          <CopyButton :value="profile.account.legicUid"></CopyButton>
        </ListItem>
      </v-list>
    </v-card>

    <v-card v-if="isEmployeeInfoVisible" class="mb-4" v-bind="$attrs">
      <v-system-bar>Anstellung</v-system-bar>
      <v-list>
        <ListItem title="Personalkategorie">
          <LookupValue :value="profile.employeeCategory" />
        </ListItem>
        <ListItem title="Personalnummer">
          <StaticText :value="profile.employeeNumber" />
        </ListItem>
        <ListItem title="Eintrittsdatum">
          <DateValue :value="profile.entryDate" />
        </ListItem>
        <ListItem title="Austrittsdatum">
          <DateValue :value="profile.exitDate" />
        </ListItem>
      </v-list>
      <v-card-text v-if="isCommentsVisible"
        >Diese Informationen sind für Schuladministration sichtbar.</v-card-text
      >
    </v-card>

    <v-card v-if="isStudentInfoVisible" class="mb-4" v-bind="$attrs">
      <v-system-bar>Bildungsgang</v-system-bar>
      <v-list>
        <ListItem title="Anmeldestatus">
          <LookupValue :value="profile.enrollmentStatus" />
        </ListItem>
        <ListItem title="rechtliche Lage">
          <LookupValue :value="profile.legalStatus" />
        </ListItem>
        <ListItem title="Eintrittsdatum">
          <DateValue :value="profile.entryDate" />
        </ListItem>
        <ListItem title="Austrittsdatum">
          <DateValue :value="profile.exitDate" />
        </ListItem>
      </v-list>
      <v-card-text v-if="isCommentsVisible"
        >Diese Informationen sind für die Mitarbeitenden der Schule
        sichtbar.</v-card-text
      >
    </v-card>

    <v-card v-if="isStudentInfoVisible" class="mb-4" v-bind="$attrs">
      <v-system-bar>Fächerprofil</v-system-bar>
      <v-list>
        <ListItem title="zweisprachiger Unterricht (BI)">
          <LookupValue long :value="profile.bilingual" />
        </ListItem>
        <ListItem title="Kunstfach (KU)">
          <LookupValue long :value="profile.artsSubject" />
        </ListItem>
        <ListItem title="dritte Sprache (S3)">
          <LookupValue long :value="profile.thirdLanguage" />
        </ListItem>
        <ListItem title="Schwerpunktfach (SF)">
          <LookupValue long :value="profile.majorSubject" />
        </ListItem>
        <ListItem title="Schwerpunktfach plus (SF+)">
          <LookupValue long :value="profile.majorSubjectPlus" />
        </ListItem>
        <ListItem title="Ergänzungsfach (EF)">
          <LookupValue long :value="profile.complementarySubject" />
        </ListItem>
        <ListItem title="Sprache plus (S+)">
          <LookupValue long :value="profile.languagePlus" />
        </ListItem>
        <ListItem title="zweite Sprache (S2)">
          <LookupValue long :value="profile.secondLanguage" />
        </ListItem>
        <ListItem title="Sport (SP)">
          <LookupValue long :value="profile.physicalEducation" />
        </ListItem>
        <ListItem title="Nachhaltige Entwicklung extended (NExt)">
          <StaticText :value="profile.next" />
        </ListItem>
        <ListItem title="fünftes Prüfungsfach (P5)">
          <LookupValue long :value="profile.fifthSubject" />
        </ListItem>
      </v-list>
      <v-card-text v-if="isCommentsVisible"
        >Diese Informationen sind für die Mitarbeitenden der Schule und die
        eigene Klasse sichtbar.</v-card-text
      >
    </v-card>
    <v-card v-if="isStatisticsInfoVisible">
      <v-system-bar>Statistik</v-system-bar>
      <v-list>
        <ListItem title="Heimatort">
          <StaticText :value="profile.placeOfOrigin" />
        </ListItem>
        <ListItem title="Wohngemeinde">
          <LookupValue :value="profile.municipality" />
        </ListItem>
        <ListItem title="Staatsangehörigkeit">
          <LookupValue :value="profile.citizenship" />
        </ListItem>
        <ListItem title="Erstsprache">
          <LookupValue :value="profile.nativeLanguage" />
        </ListItem>
      </v-list>
      <v-card-text v-if="isCommentsVisible"
        >Diese Informationen werden anonymisiert für die Schulstatistik des
        Bundesamts für Statistik benötigt und sind nur für Kanzlei und
        Schulleitung sichtbar.</v-card-text
      >
    </v-card>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { studentDutyIcon, studentSpecialIcon } from "common/utils/icons.js";
import CopyButton from "common/components/CopyButton.vue";
import DateValue from "common/components/DateValue.vue";
import DepartmentChip from "common/components/DepartmentChip.vue";
import ListItem from "@/components/ListItem.vue";
import LookupValue from "common/components/LookupValue.vue";
import PhoneNumber from "common/components/Person/PhoneNumber.vue";
import SchoolClassChip from "common/components/SchoolClassChip.vue";
import StaticText from "common/components/StaticText.vue";

import { age } from "common/utils/date.js";

export default defineComponent({
  name: "PersonProfile",
  components: {
    CopyButton,
    DateValue,
    DepartmentChip,
    ListItem,
    LookupValue,
    PhoneNumber,
    SchoolClassChip,

    StaticText,
  },
  props: {
    person: { type: Object, default: null },
  },
  data() {
    return {
      loading: false,
      profile: {},
    };
  },
  watch: {
    person() {
      this.fetchData();
    },
  },
  computed: {
    hasPerson() {
      return this.person && this.person.id;
    },
    isAccountPresent() {
      return this.profile && this.profile.account;
    },
    isAccountVisible() {
      return this.$_hasRole("schoolAdmin") || this.$_isPerson(this.person);
    },
    isCommentsVisible() {
      return this.$_isPerson(this.person);
    },
    isEmployeeInfoVisible() {
      return (
        this.hasPerson &&
        (this.person.type.code === "employee" ||
          this.person.type.code === "teacher")
      );
    },
    isSchoolProfilePresent() {
      return this.person.type.code !== "legalGuardian";
    },
    isStatisticsInfoVisible() {
      return (
        this.isStudentInfoVisible &&
        (this.$_hasRole("schoolAdmin") || this.$_isPerson(this.person))
      );
    },
    isStudentInfoVisible() {
      return this.hasPerson && this.person.type.code === "student";
    },
  },
  methods: {
    age,
    studentDutyIcon,
    studentSpecialIcon,
    async fetchData() {
      this.profile = {};
      if (!this.hasPerson) return;
      this.loading = true;
      if (this.isSchoolProfilePresent) {
        this.profile = await this.apiGet({
          resource: "person/profile",
          id: this.person.id,
        });
      }
      this.loading = false;
    },
  },
  created() {
    this.fetchData();
  },
});
</script>
